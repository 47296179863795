<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="''" v-if="currentLesson !== false">
          <template v-slot:title>
            <p>Урок - {{currentLesson.subjectName}}</p>
            <p>// Преподаватель - {{currentLesson.teacherName}}</p>
          </template>
          <template v-slot:toolbar>
<!--            <b-button type="reset" @click="startVideoLesson()" variant="danger">Подключиться</b-button>-->
            <a class="btn btn-danger" :href="currentLesson.roomName" target="_blank">Подключиться</a>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet>
          <template v-slot:title>
            {{ $t("student.dashboard") }}
          </template>
          <template v-slot:toolbar>
            <b-form-datepicker 
                id="example-datepicker" 
                v-model="date" 
                class="mb-2" 
                @context="changeDate" 
                locale="ru"
                start-weekday="1"
                :date-disabled-fn="dateDisabled"
            >
            </b-form-datepicker>
          </template>
          <template v-slot:body>
            <div>
              <b-modal id="video-modal" ref="video-modal" size="xl">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <div id="meet"></div>
                <template v-slot:modal-footer>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                    $t("common.cancel")
                    }}</b-button>
                </template>
              </b-modal>
            </div>

            <div class="mobile-day-select">
              <b-form-select v-model="marksTabIndex" :options="daysIndexs"></b-form-select>
            </div>
            <b-card no-body>
              <b-tabs pills card vertical v-model="marksTabIndex" id="marksTabs">
                <b-tab v-for="(item, index) in days"
                       :key="item.subjectDate">
                  <template v-slot:title>
                    {{$t("studentTimeTable.day" + (index +1))}}
                    <br>
                    {{ item.subjectDateString }}
                  </template>
                  <b-card-title>{{$t("studentTimeTable.day" + (index +1))}}</b-card-title>
                  <b-card-body>
                    <div style="overflow: auto">
                      <table class="table table-bordered">
                        <thead class="thead-light">
                        <tr>
                          <th width="5%">#</th>
                          <th width="20%">Предмет</th>
                          <th width="*">Домашнее задание</th>
                          <th width="10%">Замечание</th>
                          <th width="10%">Оценка</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="lessonIndex in lessonNumber" :key="lessonIndex">
                          <td>{{ lessonIndex }}</td>
                          <td>
                            <template v-if="typeof item.lessons[lessonIndex -1] != 'undefined'">
                              {{item.lessons[lessonIndex -1].subjectName}}
                            </template>
                          </td>
                          <td>
                            <template v-if="typeof item.lessons[lessonIndex -1] != 'undefined'">
                              {{item.lessons[lessonIndex -1].homework}}
                            </template>
                          </td>  
                          <td>
                            <template v-if="typeof item.lessons[lessonIndex -1] != 'undefined'">
                              {{item.lessons[lessonIndex -1].teacherNote}}
                            </template>
                          </td>
                          <td>
                            <template v-if="typeof item.lessons[lessonIndex -1] != 'undefined'">
                              {{showMark(item.lessons[lessonIndex -1].mark)}}
                              {{showMark2(item.lessons[lessonIndex -1].mark2)}}
                            </template>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-card-body>
                </b-tab>
              </b-tabs>
            </b-card>
          </template>
        </KTPortlet>

        <KTPortlet>
          <template v-slot:title>
            Предметы
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <b-tabs pills card>
              <b-tab>
                <template v-slot:title>
                  I четверть
                </template>
                <StudentMarksByQuarter :student-id="0" :mode="'student'" :quarter="1" ref="studentMarksByQuarter11"></StudentMarksByQuarter>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  II четверть
                </template>
                <StudentMarksByQuarter :student-id="0" :mode="'student'" :quarter="2" ref="studentMarksByQuarter22"></StudentMarksByQuarter>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  III четверть
                </template>
                <StudentMarksByQuarter :student-id="0" :mode="'student'" :quarter="3" ref="studentMarksByQuarter33"></StudentMarksByQuarter>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  IV четверть
                </template>
                <StudentMarksByQuarter :student-id="0" :mode="'student'" :quarter="4" ref="studentMarksByQuarter44"></StudentMarksByQuarter>
              </b-tab>
            </b-tabs>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
  .nav-tabs{
    display: none;
  }

/*  table, th, td {
    border: 1px solid black;
  }*/
  .hw-title{
    font-style: italic;
  }

  .mobile-day-select{
    display: none;
  }
  @media (max-width: 767px) {

    #marksTabs div.col-auto{
      display: none;
    }

    .mobile-day-select{
      display: block;
    }
  }
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
const JitsiMeetExternalAPI = require("lib-jitsi-meet-dist/dist/external_api.min");
import { VIDEO_STEAM_SERVER_URL } from "@/common/config";
import StudentMarksByQuarter from "../schools/studentInfo/partials/StudentMarksByQuarter.vue";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "studentDashboard",
  components: {
    StudentMarksByQuarter,
    KTPortlet
  },
  data() {
    return {
      date: false,
      tabIndex: 0,
      marksTabIndex: 0,
      days: {},
      lessons: {},
      lessonNumber: [1, 2, 3, 4, 5, 6, 7],
      currentLesson: false,
      schools: null,
      dialog: true,
      form: {
        nameRu: "",
        nameKg: "",
        address: "",
        contactPhone: ""
      },
      show: true,
      daysIndexs: [/*
        { value: 1, text: $t("studentTimeTable.day1") },
        { value: 2, text: $t("studentTimeTable.day2") },
        { value: 3, text: $t("studentTimeTable.day3") },
        { value: 4, text: $t("studentTimeTable.day4") },
        { value: 5, text: $t("studentTimeTable.day5") },
        { value: 6, text: $t("studentTimeTable.day6") },*/
      ]
    };
  },
  mounted() {
    this.reloadData();
    //this.getCurrentVideoLesson();
    //setInterval(()=>this.getCurrentVideoLesson(), 30000);
  },
  methods: {
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0;
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      ApiService.postSecured("admin/save", this.form)
        .then(function() {
          $this.$refs["school-modal"].hide();
          $this.reloadData();
          $this.cleanModel();
        })
        .catch(({ response }) => {
          console.log(response);
          $this.reloadData();
        });
    },
    onReset() {},
    reloadData() {
      let $this = this;
      let date = this.date;
      if(date == false){
        date = new Date();
      }
      ApiService.querySecured("marks/getForStudent", {
        params: {date: date }
      })
        .then(({ data }) => {
          $this.days = data.marks.markDays;

          $this.daysIndexs = [];
          for (let i in $this.days) {
            let day = $this.days[i];
            $this.lessons[day.subjectDateString] = {};
            for (let ii in day.lessons) {
              let lesson = day.lessons[ii];
              $this.lessons[day.subjectDateString][
                parseInt(lesson.subjectNumber)
              ] = lesson;
            }


            let ii = parseInt(i);
            $this.daysIndexs.push({
              value: ii,
              text: $this.$t("studentTimeTable.day" + (ii + 1)) + ", " + day.subjectDateString
            })
          }

          console.log("asas");
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    getCurrentVideoLesson(){
      let date = new Date();
      let $this = this;
      ApiService.querySecured("lesson/getForStudent", {
        params: {date: date }
      })
              .then(({ data }) => {
                if(data.streamId != null){
                  $this.currentLesson = data.streamId;
                }else{
                  $this.currentLesson = false;
                }

                console.log("asas");
              })
              .catch(({ response }) => {
                console.log(response);
              });
    },
    startVideoLesson(){
      let $this = this;
      this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {

        console.log('Modal is about to be shown', bvEvent, modalId);

        if(modalId == 'video-modal'){

          document.querySelector('#meet').innerHTML = '';

          const domain = VIDEO_STEAM_SERVER_URL;
          const options = {
            roomName: $this.currentLesson.roomName,
            width: 700,
            height: 400,
            parentNode: document.querySelector('#meet')
          };
          let api = new JitsiMeetExternalAPI(domain, options);
          api.executeCommand('password', '123');
          api.executeCommand('displayName', 'New Nickname');
        }
      });

      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        console.log('Modal is about to be shown', bvEvent, modalId);
        if(modalId == 'video-modal'){
          document.querySelector('#meet').innerHTML = '';
        }
      });

      this.$refs["video-modal"].show();
    },
    showMark(mark){
      return DictionariesService.displayMark(mark);
    },
    showMark2(mark2){
      if(mark2 != null){
        return  "/" + DictionariesService.displayMark(mark2)
      }
    },
    changeDate(){
      this.reloadData()
    }
  }
};
</script>
